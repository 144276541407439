import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  ArrayField,
  Datagrid,
  SingleFieldList,
  ChipField,
  WithRecord,
  useRecordContext,
} from "react-admin";
import FacebookIcon from "../svgs/facebook";
import GoogleIcon from "../svgs/google";
import AppleIcon from "../svgs/apple";
import PasswordIcon from "../svgs/password";
import { Typography } from "@material-ui/core";
import { StatusButton } from "../payments/StatusButton";
import { competitionConfig } from "../competitionConfig";

const getProviderIcon = (provider) => {
    if(provider === 'google.com') {
        return <GoogleIcon />
    }
    if(provider === 'apple.com') {
        return <AppleIcon />
    }
    if(provider === 'facebook.com') {
        return <FacebookIcon />
    }
    if(provider === 'password') {
        return <PasswordIcon />
    }

    return null;
}

const ProviderEmail = (props) => {
  const { source } = props;
  const record = useRecordContext(source);

  return (
    <div>
      {record?.loginProvider.map((prov, i) => {
        return (
          <div key={i}>
            {prov.email}
            <span>
              {getProviderIcon(prov.provider)}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export const UserShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <Typography variant="h5">User details</Typography>

        <TextField source="id" options={{ disabled: true }} />
        <TextField source="name" />
        <TextField source="email" />
        <ArrayField source="loginProvider" label="Login emails">
          <ProviderEmail />
        </ArrayField>
        <TextField source="phone" />
        <TextField source="occupation" />
        <TextField source="company" />

        <ReferenceField
          source="id"
          reference={`${competitionConfig.path}/users`}
          label={`${competitionConfig.year} status`}
          allowEmpty
          link={() => ``}
        >
          <StatusButton source="status" />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};
