import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {ArrayField, Datagrid, ReferenceField, Show, SimpleShowLayout, TextField} from "react-admin";
import {Typography} from "@material-ui/core";
import {competitionConfig} from "../competitionConfig";

export const TeamShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <Typography variant="h5">Team details</Typography>

            <TextField source="id"/>
            <TextField source="leader" labe="Leader ID"/>

            <ReferenceField source="leader"
                            reference={`users`}
                            label="Leader name"
                            allowEmpty link={() => ``}>
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="leader"
                            reference={`users`}
                            label="Leader email"
                            allowEmpty link={() => ``}>
                <TextField source="email"/>
            </ReferenceField>

            <ArrayField label="Members" source="members">
                <Datagrid>
                    {/*<TextField source="id"/>*/}
                    <ReferenceField source="uid"
                                    reference={`users`}
                                    label="Member name"
                                    allowEmpty
                                    link={(record) => `/${competitionConfig.path}/users/${record.uid}/show`}>
                        <TextField source="name"/>
                    </ReferenceField>
                    <ReferenceField source="uid"
                                    reference={`users`}
                                    label="Member email"
                                    allowEmpty
                                    link={(record) => `/${competitionConfig.path}/users/${record.uid}/show`}>
                        <TextField source="email"/>
                    </ReferenceField>
                    <TextField source="confirmed"/>
                </Datagrid>
            </ArrayField>

        </SimpleShowLayout>
    </Show>
);
