import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
    BooleanField,
    NumberField,
    ReferenceField,
    SelectField,
    Show,
    SimpleShowLayout,
    Tab,
    TabbedShowLayout,
    TextField
} from "react-admin";
import {competitionConfig} from "../competitionConfig";
import {Typography} from "@material-ui/core";
import {StepsList} from "../steps/StepsList";
import {UserDelilveryAddress} from "./UserDelilveryAddress";

export const CompetitionUserShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <Typography variant="h5">Metadata for this year</Typography>
            <TextField source="id"
                       options={{disabled: true}}/>
            <ReferenceField source="id"
                            reference="users"
                            label="Name"
                            link="show">
                <TextField source="name"
                           options={{disabled: true}}/>
            </ReferenceField>
            <ReferenceField source="id"
                            reference="users"
                            label="Email"
                            link="show">
                <TextField source="email"
                           options={{disabled: true}}/>
            </ReferenceField>
            <BooleanField source="companiesChallenge"/>
            <TabbedShowLayout>
                <Tab label="payments">
                    <ReferenceField source="id"
                                    reference={`${competitionConfig.path}/user-payments`}
                                    label={`Package`}
                                    allowEmpty
                                    link={() => ``}>
                        <TextField source="package"/>
                    </ReferenceField>

                    <SelectField source="status"
                                 choices={[
                                     {id: 'confirmed', name: 'Confirmed'},
                                     {id: 'canceled', name: 'Canceled'}
                                 ]}/>

                    <TextField multiline="true" source="pickupLocation" fullWidth/>
                    <UserDelilveryAddress />
                </Tab>
                <Tab label="Team">
                    <ReferenceField source="team"
                                    reference={`${competitionConfig.path}/teams`}
                                    label={`Team`}
                                    allowEmpty>
                        <TextField source="id"/>
                    </ReferenceField>
                    <Typography addLabel label="Random team"/>
                    <BooleanField source="randomTeam"/>
                </Tab>
                <Tab label="Steps">
                    <NumberField source="totalSteps" defaultValue={0} variant="h5"/>
                    <Typography addLabel label="Daily steps"/>
                    <StepsList {...props}/>
                </Tab>
            </TabbedShowLayout>
        </SimpleShowLayout>
    </Show>
);
