import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
    ArrayField,
    ChipField,
    Datagrid, downloadCSV,
    List,
    NumberField,
    ReferenceField,
    SingleFieldList,
    TextField,
    TextInput, useListContext
} from "react-admin";
import {competitionConfig} from "../competitionConfig";

import jsonExport from 'jsonexport/dist';

const UserFilter = [
    <TextInput source="id" label="Search through team names" alwaysOn/>,
];
const LengthField = (props) => {
    const { ids, loaded } = useListContext(props);

    return loaded ? ids.length : null;
};

const exporter = (teams, fetchRelatedRecords) => {
        const teamData = teams
        .sort( (a, b) => {
            return b.totalSteps - a.totalSteps;
        })
        .map(team => ({
        name: team.id,
        totalSteps: Number(team.totalSteps).toString(),
        noOfMembers: team.members.length
        }));

    jsonExport(teamData, {
        headers: ['name', "noOfMembers", "totalSteps"] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'competition-teams'); // download as 'posts.csv` file
    });
};

export const TeamsList = (props) => (
    <List {...props} filters={UserFilter}
          perPage={25}
          sort={{field: 'id', order: 'ASC'}}
          bulkActionButtons={false}
          exporter={exporter}>
        <Datagrid rowClick="show">
            <TextField source="id" label="Name"/>
            <NumberField source="totalSteps"/>
            <ArrayField label="Members" source="members">
                    <SingleFieldList>
                        {/*<TextField source="id"/>*/}
                        <ReferenceField source="uid"
                                        reference={`users`}
                                        label="Member name"
                                        allowEmpty
                                        link={(record) => `/${competitionConfig.path}/users/${record.uid}/show`}>
                            <ChipField source="name"/>
                        </ReferenceField>
                    </SingleFieldList>
            </ArrayField>
            <ArrayField label="Number of members" source="members" sortable={false}>
                <LengthField/>
            </ArrayField>
        </Datagrid>
    </List>
);
