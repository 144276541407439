import React, {useCallback, useEffect, useState,} from 'react';
import {useDataProvider, useVersion} from "react-admin";
import {competitionConfig} from "../competitionConfig";
import {FormControl, InputLabel, MenuItem, Select, Card, CardHeader, CardContent, Grid, Typography} from "@material-ui/core";
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';

const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarExport style={{align: 'right'}} csvOptions={{allColumns: true}}/>
        </GridToolbarContainer>
    );
}

const CompanyUsersList = () => {
    const version = useVersion();
    const dataProvider = useDataProvider();
    const [data, setData] = useState([]);
    const [stats, setStats] = useState({});
    const [company, setCompany] = useState('');

    useEffect(() => {
        const newStats = {}
        data.forEach(user => {
            if(typeof user.totalSteps !== 'number') {
                console.log(user)
            }
            if (!newStats[user.company]) {
                newStats[user.company] = {
                    users: 1,
                    steps: parseInt(user.totalSteps),
                    averageSteps: parseInt(user.totalSteps)
                }
            } else {
                newStats[user.company].users = 1 + newStats[user.company].users;
                newStats[user.company].steps = newStats[user.company].steps + parseInt(user.totalSteps);
                newStats[user.company].averageSteps = Math.round(newStats[user.company].steps / newStats[user.company].users);
            }
        })

        setStats(newStats)
    }, [data])

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 300
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 200,
        }, {
            field: 'email',
            headerName: 'Email',
            width: 200,
        },
        {
            field: 'company',
            headerName: 'Company',
            width: 200,
        },
        {
            field: 'totalSteps',
            headerName: 'Total Steps',
            type: 'number',
            valueFormatter: (params) => {
                // from 100,000 transform to 100000
                const valueFormatted = Number(params.value).toString();
                return `${valueFormatted}`;
            },
            width: 200,
        }
    ];

    const fetchData = useCallback(async () => {
        // use node cache and cron

        const {data: competitionUsers} = await dataProvider.getList(
            `${competitionConfig.path}/users`, {
                pagination: {page: 1, perPage: 3000},
                sort: {field: 'totalSteps', order: 'DESC'},
                filter: {companiesChallenge: true},
            });

        let usersMap = {};

        competitionUsers.forEach((user) => {
            if (user?.id) {
                usersMap[user.id] = user;
            }
        })

        const {data: users} = await dataProvider.getMany(
            `users`, {
                ids: Object.keys(usersMap),
                pagination: {page: 1, perPage: 3000}
            });

        users.forEach((user) => {
            usersMap[user.id] = {...usersMap[user.id], ...user};
        });

        setData(Object.values(usersMap));
    }, [dataProvider]);

    useEffect(() => {
        fetchData();
    }, [version]);

    const handleChange = (event) => {
        setCompany(event.target.value);
    }

    return (
        <>
            <FormControl style={{marginBottom: 30}}>
                <InputLabel id="company" style={{maxWidth: 200}}>Company</InputLabel>
                <Select
                    style={{maxWidth: 300}}
                    value={company}
                    onChange={handleChange}
                >
                    <MenuItem value=''>None</MenuItem>
                    <MenuItem value='Accesa'>Accesa</MenuItem>
                    <MenuItem value='Aegon România'>Aegon România</MenuItem>
                    <MenuItem value='Archive 360 Soft'>Archive 360 Soft</MenuItem>
                    <MenuItem value='ATU Consulting'>ATU Consulting</MenuItem>
                    <MenuItem value='Banca Transilvania'>Banca Transilvania</MenuItem>
                    <MenuItem value='Betfair Romania Development'>Betfair Romania Development</MenuItem>
                    <MenuItem value='BROADRIDGE'>BROADRIDGE</MenuItem>
                    <MenuItem value='CES ROMANIA'>CES ROMANIA</MenuItem>
                    <MenuItem value='Colors in Projects'>Colors in Projects</MenuItem>
                    <MenuItem value='Emerson'>Emerson</MenuItem>
                    <MenuItem value='EuroGsm'>EuroGsm</MenuItem>
                    <MenuItem value='fischer'>fischer</MenuItem>
                    <MenuItem value='GENERIX'>GENERIX</MenuItem>
                    <MenuItem value='GEP'>GEP</MenuItem>
                    <MenuItem value='Goodwill Consulting'>Goodwill Consulting</MenuItem>
                    <MenuItem value='Groupama Asigurari'>Groupama Asigurari</MenuItem>
                    <MenuItem value='S.C. IKON SOFT S.R.L.'>S.C. IKON SOFT S.R.L.</MenuItem>
                    <MenuItem value='Iron Mountain'>Iron Mountain</MenuItem>
                    <MenuItem value='JIBE'>JIBE</MenuItem>
                    <MenuItem value='Jpard Solutions'>Jpard Solutions</MenuItem>
                    <MenuItem value='MDPI'>MDPI</MenuItem>
                    <MenuItem value='Mindera Romania'>Mindera Romania</MenuItem>
                    <MenuItem value='Montran'>Montran</MenuItem>
                    <MenuItem value='msg Romania'>msg Romania</MenuItem>
                    <MenuItem value='NIXU CYBERSECURITY'>NIXU CYBERSECURITY</MenuItem>
                    <MenuItem value='Office Depot'>Office Depot</MenuItem>
                    <MenuItem value='PitechPlus'>PitechPlus</MenuItem>
                    <MenuItem value='Porsche Engineering Romania'>Porsche Engineering Romania</MenuItem>
                    <MenuItem value='Routier European Transport'>Routier European Transport</MenuItem>
                    <MenuItem value='RWS'>RWS</MenuItem>
                    <MenuItem value='SIGNAL IDUNA'>SIGNAL IDUNA</MenuItem>
                    <MenuItem value='Steelcase'>Steelcase</MenuItem>
                    <MenuItem value='SYKES Romania'>SYKES Romania</MenuItem>
                    <MenuItem value='Utiben'>Utiben</MenuItem>
                    <MenuItem value='WebBeds'>WebBeds</MenuItem>
                    <MenuItem value='Westernacher Consulting'>Westernacher Consulting</MenuItem>
                    <MenuItem value='ZegaSoftware'>ZegaSoftware</MenuItem>
                </Select>
            </FormControl>
            {company && <div style={{ display: 'flex' }}>
                <Card
                    style={{width: '100%',
                    margin: '0.2em',
                    display: 'inline-block',
                    verticalAlign: 'top'}}>
                    <CardContent>
                        <Grid>
                            <Typography><b>Total users:</b> {stats?.[company]?.users || 0}</Typography>
                            <Typography><b>Total steps:</b> {stats?.[company]?.steps || 0}</Typography>
                            <Typography><b>Average steps:</b> {stats?.[company]?.averageSteps || 0}</Typography>
                        </Grid>
                    </CardContent>
                </Card>
            </div>}
            <DataGrid
                rows={data}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[5]}
                filterModel={{
                    items: [{columnField: 'company', operatorValue: 'contains', value: company}],
                }}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </>
    );
};

export const CompaniesChallenge = (props) => (
    <CompanyUsersList/>
)
